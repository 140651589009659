import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ApiHelper from "../../utillity/ApiHelper";
import APIURLConstants from "../../config/config";
import Helper from "../../utillity/helper";
import { setSiteState } from "../../hookState/SiteState";
import { useNavigate } from "react-router-dom";
import RouteConstants from "../../constants/RouteConstant";
import validator from "validator";
// import AppleIcon from "@mui/icons-material/Apple";
import GoogleIcon from "../../assets/google.svg";
import FacebookIcon from "../../assets/fb.svg";
import InstagramIcon from "../../assets/insta.svg";
import AmazonIcon from "../../assets/amazon.svg";

import { makeStyles } from "@mui/styles";

import { Divider, CircularProgress } from "@mui/material";
import {
	setSocialLoginState,
	changeSocialState,
} from "../../hookState/SocialLoginState";
export default function Register() {
	const useStyles = makeStyles((theme) => ({
		margin: {
			margin: theme.spacing(1),
		},
		socialButton: {
			margin: theme.spacing(1),
		},

		textField: {
			width: 400,
			[theme.breakpoints.down("md")]: {
				width: 320,
			},
			display: "flex",
			"& .MuiOutlinedInput-root": {
				"& fieldset": {
					borderColor: theme.palette.secondary.main,
				},
				"&:hover fieldset": {
					borderColor: theme.palette.secondary.main,
				},
				"&.Mui-focused fieldset": {
					borderColor: theme.palette.secondary.main,
				},
			},
			"&:hover .MuiInputLabel-root": {
				color: theme.palette.secondary.main,
			},
			"& .Mui-focused.MuiInputLabel-root": {
				color: theme.palette.secondary.main,
			},
		},
		outlinedInput: {
			"&:hover .MuiInputAdornment-root .MuiSvgIcon-root": {
				color: theme.palette.secondary.main,
			},
			"&.Mui-focused .MuiInputAdornment-root .MuiSvgIcon-root": {
				color: theme.palette.secondary.main,
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					width: "200px",
					height: "70px",
				},
			},
		},
	}));
	// const [regData, setRegData] = React.useState({});
	let navigate = useNavigate();
	const classes = useStyles();

	const [firstNameErr, setFirstNameErr] = React.useState("");
	const [lastNameErr, setLastNameErr] = React.useState("");
	const [emailErr, setEmailErr] = React.useState("");
	const [loading, setLoading] = React.useState(false);
	const [passwordErr, setPasswordErr] = React.useState("");

	const handleSubmit = (event) => {
		event.preventDefault();
		const data = new FormData(event.currentTarget);
		// setRegData(new FormData(event.currentTarget));
		if (data.get("firstName") === "") {
			setFirstNameErr("First Name required");
			return;
		}
		if (data.get("lastName") === "") {
			setLastNameErr("Last Name required");
			return;
		}
		if (data.get("email") === "") {
			setEmailErr("Email required");
			return;
		} else {
			const email = data.get("email").trim();
			const flag = email !== "" ? validateEmail(email) : true;
			if (!flag) {
				const emlErr = flag ? "" : "Please enter valid Email";
				setEmailErr(emlErr);
				return;
			}
		}

		if (data.get("password") === "") {
			setPasswordErr("Password required");
			return;
		}
		setLoading(true);
		ApiHelper.post(APIURLConstants.POST_SIGN_UP, data)
			.then((res) => {
				console.log(res);
				if (res.data) {
					if (res.data.success) {
						setLoading(false);
						Helper.setStorage("userInfo", res.data.result);
						window.dataLayer.push({
							event: "sign_up",
							user_id: res.data.result.user.id,
						});

						setSiteState(res.data.result);

						const cart = Helper.getStorage("cart");
						let cartAsin = [];
						if (cart) {
							let cartCopy = [...cart];
							cartAsin = cartCopy.map((key) => key.asin);
							const data = {
								cartAsin: cartAsin,
								user_id: res.data.result.user.id,
							};
							ApiHelper.post(APIURLConstants.add_products_cart, data)
								.then((response) => {
									if (res.data.success) {
										Helper.removeStorage("cart");
										Helper.removeStorage("dataLayerCart");
									}
								})
								.catch((error) => {
									console.log(error);
								});
						}

						navigate(RouteConstants.LANDING);
					} else {
						setLoading(false);
						setEmailErr("The email has already been taken.");
						return;
					}
				} else {
					// disableButton.set(false);
					// setSnackBarProps({
					// 	...snackBarProp,
					// 	openSnack: true,
					// 	variant: "error",
					// 	message: "This event name already exists",
					// });
				}
				// loading.set(false);
			})
			.catch((error) => {
				setLoading(false);
				console.log(error);
			});
	};

	const validateEmail = (value) => {
		value = value.trim();
		if (validator.isEmail(value)) {
			return true;
		} else return false;
	};

	const loginButton = (provider) => {
		changeSocialState();
		setSocialLoginState(provider);
		Helper.setStorage("loginOption", provider);
		ApiHelper.get(`${APIURLConstants.GOOGLE + provider}`)
			.then((res) => {
				console.log(res.data);
				window.location = res.data;
			})
			.catch((error) => {
				console.log(error);
			});
	};
	return (
		<Box
			sx={{
				width: { xs: 290, md: 400 },

				marginTop: 8,
				display: "flex",
				flexDirection: "column",
			}}
		>
			<Typography
				component="h1"
				variant="h5"
				alignContent={"flex-start"}
				fontWeight={600}
			>
				Sign up
			</Typography>
			<Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={6}>
						<TextField
							autoComplete="given-name"
							name="firstName"
							required
							fullWidth
							id="firstName"
							label="First Name"
							autoFocus
							color="secondary"
							error={firstNameErr.length > 0 ? true : false}
							helperText={firstNameErr.length > 0 ? firstNameErr : ""}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							required
							fullWidth
							id="lastName"
							label="Last Name"
							name="lastName"
							autoComplete="family-name"
							color="secondary"
							error={lastNameErr.length > 0 ? true : false}
							helperText={lastNameErr.length > 0 ? lastNameErr : ""}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							required
							fullWidth
							id="email"
							label="Email Address"
							name="email"
							autoComplete="email"
							color="secondary"
							error={emailErr.length > 0 ? true : false}
							helperText={emailErr.length > 0 ? emailErr : ""}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							fullWidth
							id="phone"
							label="Mobile Number"
							name="phone"
							autoComplete="mobile"
							color="secondary"
							// error={mobileErr.length > 0 ? true : false}
							// helperText={mobileErr.length > 0 ? mobileErr : ""}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							required
							fullWidth
							name="password"
							label="Password"
							type="password"
							id="password"
							autoComplete="new-password"
							color="secondary"
							error={passwordErr.length > 0 ? true : false}
							helperText={passwordErr.length > 0 ? passwordErr : ""}
						/>
					</Grid>
					{/* <Grid item xs={12}>
						<FormControlLabel
							control={<Checkbox value="allowExtraEmails" color="secondary" />}
							label="I want to receive inspiration, marketing promotions and updates via email."
						/>
					</Grid> */}
				</Grid>
				<Button
					type="submit"
					fullWidth
					variant="contained"
					sx={{
						mt: 3,
						mb: 2,
						color: "primary.main",
						bgcolor: "tertiary.main",
					}}
					color="inherit"
				>
					{loading ? <CircularProgress /> : "Sign Up"}
				</Button>
				<Grid container justifyContent="center">
					<Grid item>
						<Typography sx={{ color: "black" }} fontSize={14}>
							Already have an account?{" "}
							<Link
								href="#"
								variant="body2"
								sx={{ color: "#0072B1", fontSize: 13 }}
							>
								Sign in
							</Link>
						</Typography>
					</Grid>
				</Grid>
				<Box
					className={classes.margin}
					sx={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						width: { xs: 290, md: 410 },
						color: "secondary.main",
					}}
				>
					<Divider spacing={2}>
						<Typography
							color="secondary"
							textTransform="capitalize"
							variant="subtitle1"
						>
							Or Sign In With
						</Typography>
					</Divider>
					<Box
						className={classes.margin}
						sx={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "center",
						}}
					>
						<Box
							component="img"
							sx={{
								height: 50,
								marginTop: 1,
								cursor: "pointer",
								marginLeft: "10px",
								marginRight: "10px",
							}}
							alt="logo"
							src={GoogleIcon}
							onClick={() => loginButton("google")}
						/>
						{/* <Box
							component="img"
							sx={{
								height: 50,
								marginTop: 1,
								cursor: "pointer",
								marginLeft: "10px",
								marginRight: "10px",
							}}
							alt="logo"
							src={FacebookIcon}
							onClick={() => loginButton("facebook")}
						/>
						<Box
							component="img"
							sx={{
								height: 50,
								marginTop: 1,
								cursor: "pointer",
								marginLeft: "10px",
								marginRight: "10px",
							}}
							alt="logo"
							src={InstagramIcon}
							onClick={() => loginButton("instagram")}
						/>
						<Box
							component="img"
							sx={{
								height: 50,
								marginTop: 1,
								cursor: "pointer",
								marginLeft: "10px",
								marginRight: "10px",
							}}
							alt="logo."
							src={AmazonIcon}
							onClick={() => loginButton("amazon")}
						/> */}
					</Box>
				</Box>
			</Box>
		</Box>
	);
}
