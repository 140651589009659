import React, { useEffect, useState } from "react";
import APIURLConstants from "../../config/config";
import { useSiteState } from "../../hookState/SiteState";
import ApiHelper from "../../utillity/ApiHelper";
import { Box, TextField, Button, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbars from "../../components/Snackbar/Snackbar";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import moment from "moment";

export const Subscription = () => {
	const state = useSiteState();
	const [data, setData] = React.useState({});

	const [loading, setLoading] = React.useState(true);

	const [snackBarProps, setSnackBarProps] = React.useState({
		openSnack: false,
		variant: "error",
		message: "",
	});
	const getSubscriptionDetails = () => {
		const data = { user_id: state.user.id.get() };

		ApiHelper.post(APIURLConstants.SUBSCRIPTION_DETAILS, data)
			.then((res) => {
				setLoading(false);
				console.log("======>", res);
				setData(res.data.result.data);
			})
			.catch((error) => {
				console.log(error);
			});
	};
	const handleSubmit = () => {
		setLoading(true);
		const data = {
			user_id: state.user.id.get(),
		};

		ApiHelper.post(APIURLConstants.UPDATE_USER, data)
			.then((res) => {
				setLoading(false);
				if (res.data.success) {
					setSnackBarProps({
						openSnack: true,
						variant: "success",
						message: "Something is wrong",
					});
					getSubscriptionDetails();
				} else {
					setSnackBarProps({
						openSnack: true,
						variant: "error",
						message: "User details are updated",
					});
				}
			})
			.catch((error) => {
				setLoading(false);
				console.log(error);
				setSnackBarProps({
					openSnack: true,
					variant: "error",
					message: "Something wrong",
				});
			});
	};

	useEffect(() => {
		getSubscriptionDetails();
	}, []);
	return (
		<Box sx={{ background: "aliceblue", height: "100%" }}>
			{loading ? (
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						height: "100%",
						width: "100%",
						justifyContent: "center",
						alignContent: "center",
						alignItems: "center",
					}}
				>
					<CircularProgress
						sx={{
							color: "secondary.main",
						}}
					/>
				</Box>
			) : (
				<Box
					sx={{
						// p: 2,
						height: "100%",
						width: "100%",
						marginLeft: { xs: 0, md: 8 },
					}}
				>
					<Typography
						component="h1"
						variant="h5"
						alignContent={"flex-start"}
						fontWeight={600}
						padding={2}
					>
						{state.user.get() !== "" &&
						state.plan.get() !== "" &&
						state.plan.get() !== undefined &&
						state.plan.get() !== null
							? "Subscription Detail"
							: "Payment History"}
					</Typography>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							// alignItems: "center",
							width: "80%",

							mx: 2,
						}}
					>
						<TableContainer component={Paper}>
							<Table size="small" aria-label="a dense table">
								<TableHead>
									<TableRow>
										{state.user.get() !== "" &&
										state.plan.get() !== "" &&
										state.plan.get() !== undefined &&
										state.plan.get() !== null ? (
											<>
												<TableCell>Plan</TableCell>
												<TableCell>Date of purchase</TableCell>
												<TableCell>Amount</TableCell>
												<TableCell>Status</TableCell>
												{/* <TableCell align="right">Description</TableCell> */}
											</>
										) : (
											<>
												<TableCell>Plan</TableCell>

												<TableCell>Date of purchase</TableCell>
												<TableCell>Amount</TableCell>
												{/* <TableCell align="right">Description</TableCell> */}
											</>
										)}
									</TableRow>
								</TableHead>
								<TableBody>
									{data && data.length > 0 ? (
										data.map((key, index) => {
											return (
												<TableRow
													key={index}
													sx={{
														"&:last-child td, &:last-child th": { border: 0 },
													}}
												>
													<TableCell component="th" scope="row">
														{key.plan_id === 1 ? "Premium" : "Single"}
													</TableCell>
													<TableCell>
														{moment(key.created_at).format("DD MMM YYYY")}
													</TableCell>
													<TableCell>{key.amount}</TableCell>
													{key.plan_id === 1 && (
														<TableCell>
															{key.status === 1 ? "Active" : "InActive"}
														</TableCell>
													)}
													{/* <TableCell align="right">{data.description}</TableCell> */}
												</TableRow>
											);
										})
									) : (
										<TableRow
											sx={{
												"&:last-child td, &:last-child th": { border: 0 },
												fontWeight: 400,
												fontSize: 13,
												mx: "auto",
												paddingLeft: 4,
											}}
										>
											{" "}
											<TableCell align="right"></TableCell>
											<TableCell align="right">
												{" "}
												<Typography
													color="initial"
													alignContent={"center"}
													marginLeft={4}
												>
													{state.user.get() !== "" &&
													state.plan.get() !== "" &&
													state.plan.get() !== undefined &&
													state.plan.get() !== null
														? "No Subscription purchased yet"
														: "Payment History not found"}
												</Typography>
											</TableCell>
										</TableRow>
									)}
									{/* {data.map((row) => (
										<TableRow
											key={row.name}
											sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
										>
											<TableCell component="th" scope="row">
												{row.name}
											</TableCell>
											<TableCell align="right">{row.calories}</TableCell>
											<TableCell align="right">{row.fat}</TableCell>
											<TableCell align="right">{row.carbs}</TableCell>
											<TableCell align="right">{row.protein}</TableCell>
										</TableRow>
									))} */}
								</TableBody>
							</Table>
						</TableContainer>
					</Box>
				</Box>
			)}
			{snackBarProps.openSnack && (
				<Snackbars
					{...snackBarProps}
					onClose={() =>
						setSnackBarProps({ ...snackBarProps, openSnack: false })
					}
				></Snackbars>
			)}
		</Box>
	);
};
